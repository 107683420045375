












import Vue from 'vue';
export default Vue.extend({
  components: {
    SetupTopnav: () => import('@/components/Setup/Layout/SetupTopnav.vue'),
    SetupSidenav: () => import('@/components/Setup/Layout/SetupSidenav.vue'),
    SetupBreadcrumbs: () => import('@/components/Setup/Layout/SetupBreadcrumbs.vue')
  }
});
