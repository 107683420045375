import { errorHandler } from '@/util/helpers';
import { APIHelper } from '@archistar/auth-frontend/src';
import { ActionContext } from 'vuex';
import { RootState } from '..';

interface CommunityState {
  masterplan: Masterplan | {};
  communities: any;
}

const state: CommunityState = {
  masterplan: {},
  communities: undefined
};

const modulePattern = {
  namespaced: true,
  state,
  actions: {
    async fetchMasterplan(store: ActionContext<CommunityState, RootState>, communityId: number): Promise<any> {
      try {
        const { data: info } = await (await APIHelper.CommunityAPI.get(`communities/${communityId}`))?.data;

        const { data: features } = await (
          await APIHelper.CommunityAPI.get(`communities/${communityId}/lots?as_geojson=1`)
        )?.data;

        const masterplan = {
          info,
          features
        };

        const centroidFeatures = masterplan.features.map(feature => ({
          // feature for Mapbox SF
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: feature.properties.centroid
          },
          properties: {
            lot_status: feature.properties.lot_status,
            type: 'Point'
          }
        }));

        masterplan.features = [...masterplan.features, ...centroidFeatures];

        store.commit('SET_MASTERPLAN', masterplan);

        return masterplan;
      } catch (error) {
        errorHandler(error, 'There was an issue while fetching community masterlan info');
        throw error.message;
      }
    },
    async fetchCommunities(store: ActionContext<CommunityState, RootState>): Promise<any> {
      try {
        const { data } = (await APIHelper.CommunityAPI.get('communities/')).data;

        store.commit('SET_COMMUNITIES', data);

        return data;
      } catch (error) {
        errorHandler(error, 'There was an issue while fetching communities list');
        throw error.message;
      }
    }
  },
  mutations: {
    SET_MASTERPLAN(state: CommunityState, payload) {
      state.masterplan = payload;
    },
    SET_COMMUNITIES(state: CommunityState, payload) {
      state.communities = payload;
    }
  }
  // getters: {
  //   isOpen: (state: CommunityState) => modalName => {
  //     return state.isModalOpen[modalName] || false;
  //   }
  // }
};

export default modulePattern;
