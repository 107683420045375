import { RouteConfig } from 'vue-router/types/router';
import Layout from '../views/Siting/Layout.vue';
import Siting from '../views/Siting/Index.vue';

export default [
  {
    path: '/siting/',
    name: 'Siting',
    component: Layout,
    props: route => Object.assign({}, route.query, route.params)
  },
  {
    path: '/siting/:sitingId',
    name: 'Siting',
    component: Siting,
    props: route => Object.assign({}, route.query, route.params),
    children: [
      {
        name: 'Manage',
        path: 'manage',
        component: () => import('@/views/Siting/Manage.vue'),
        props: route => Object.assign({}, route.query, route.params)
      }
    ]
  }
] as Array<RouteConfig>;
