














import Vue from 'vue';
import { mapState } from 'vuex';
import { AuthUser } from '@archistar/auth-frontend/src';

export default Vue.extend({
  name: 'App',
  computed: {
    ...mapState(['loading']),
    authUser() {
      return this.authUserProxy;
    }
  },
  data: () => ({
    // Hacky solution to enable watching of consts
    authUserProxy: AuthUser,
    drawer: true
  }),
  async created() {
    if (!AuthUser?.guid) this.$store.commit('SET_LOADING', true);
  },
  components: {
    Topnav: () => import('@/components/Layout/Topnav.vue')
  },
  watch: {
    authUser: {
      deep: true,
      handler(value) {
        if (value?.guid) {
          // Stop loading once auth details are present
          this.$store.commit('SET_LOADING', false);
        }
      },
      immediate: true
    }
  }
});
