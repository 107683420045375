export default [
  {
    name: 'overlays',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/planningoverlay.json',
      tileSize: 512
    }
  },
  {
    name: 'datasets_flood',
    props: {
      type: 'vector',
      tiles: ['https://staging.datasets.archistar.io/api/v1/public/layer-groups/1/tiles/{z}/{x}/{y}.pbf']
    }
  },
  {
    name: 'waggawaggaflood',
    props: {
      type: 'raster',
      url: 'https://api.archistar.ai/v1/devtiles/data/wagga_wagga_flood.json',
      tileSize: 256
    }
  },
  {
    name: 'contamination',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/contamination.json',
      tileSize: 512
    }
  },
  {
    name: 'infrastructure',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/infrastructure.json',
      tileSize: 512
    }
  },
  {
    name: 'adminbounds',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/adminbounds.json',
      tileSize: 512
    }
  },
  {
    name: 'gnaf_addresses',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/gnaf_addresses.json',
      tileSize: 512
    }
  },
  {
    name: 'zoning',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/zoning.json',
      tileSize: 512
    }
  },
  {
    name: 'buildings',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/buildings.json',
      tileSize: 512
    }
  },
  {
    name: 'properties',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/properties.json',
      tileSize: 512
    }
  },
  {
    name: 'lotarea',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/lotarea.json',
      tileSize: 512
    }
  },
  {
    name: 'plan_maxbldheightm',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/maxbldheight.json',
      tileSize: 512
    }
  },
  {
    name: 'contours',
    props: {
      type: 'vector',
      url: 'https://api.archistar.ai/v1/devtiles/data/contours.json',
      tileSize: 512
    }
  },
  {
    name: 'mapbox-terrain',
    props: {
      type: 'vector',
      url: 'mapbox://mapbox.mapbox-terrain-v2',
      tileSize: 512
    }
  }
];
