import router from '../router';
/**
 * Duplicates given object values, without the prototypal inheritance
 */
export function softCopy(val) {
  return JSON.parse(JSON.stringify(val));
}

/**
 * Compares given object values, without the prototypal inheritance
 */
export function softCompare(val1, val2) {
  return JSON.stringify(val1) === JSON.stringify(val2);
}

/**
 * Handles error code checking and Logs the error in console in a structured format when in 'development' environment
 */
export function errorHandler(error, msg) {
  if (error.response.status === 403) {
    router.push('/error/403');
  }
  if (process.env.VUE_APP_ENV === 'development') {
    console.error(
      new Error(`
        This error is only visible in 'development' mode
        \n${msg}
        \n\t${error.message}
        \n\t${JSON.stringify(error.response.data)}
      `)
    );
  }
}

/**
 * Capitalize every word of the sentence
 */
export function capitalize(str, lower = false) {
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}
