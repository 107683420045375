import Vue from 'vue';
import App from './App.vue';
import router from './router';
import DesignSystem from 'design-system/packages/archistar';
import store from './store';
import VeeValidateLaravelPlugin from './plugins/vee-validate-laravel';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import './styles/tailwind.css';
import VueTippy, { TippyComponent } from 'vue-tippy';
import { AuthUser } from '@archistar/auth-frontend/src';
import '@/util/global';
import CommunityMap from './services/SitingMap';

Vue.config.productionTip = false;

// Prototype variables
Vue.prototype.$authUser = AuthUser;
Vue.prototype.sitingMap = new CommunityMap();

// Installs
Vue.use(VeeValidateLaravelPlugin);
Vue.use(VueTippy);

// Global Registrations
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('tippy', TippyComponent);

// Vee-validate rules
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

// if (process.env.VUE_APP_ENV === 'production') {
//   Analytics.workspace.init();
// }

const app = new Vue({
  router,
  store,
  vuetify: DesignSystem,
  render: h => h(App)
}).$mount('#app');

if (process.env.NODE_ENV === 'development') window.app = app;
