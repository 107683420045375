





import SitingMap from '@/components/Siting/SitingMap.vue';
import Vue from 'vue';
export default Vue.extend({
  components: {
    SitingMap
  }
});
