import { ActionContext } from 'vuex';
import { RootState } from '../';

interface ModalState {
  isModalOpen: {
    [key: string]: boolean;
  };
}

const modulePattern = {
  namespaced: true,
  state: {
    isModalOpen: {}
  },
  actions: {
    async openModal(store: ActionContext<ModalState, RootState>, name: string): Promise<boolean> {
      console.log('Opening Modal', name);
      return true;
    },
    closeModal(store: ActionContext<ModalState, RootState>, name: string): boolean {
      console.log('Closing Modal', name);
      return true;
    }
  },
  mutations: {
    SET_MODAL(state: ModalState, payload) {
      const { modalName, isOpen } = payload;
      state.isModalOpen[modalName] = isOpen;
    }
  },
  getters: {
    isOpen: (state: ModalState) => modalName => {
      return state.isModalOpen[modalName] || false;
    }
  }
};

export default modulePattern;
