























import Vue from 'vue';
export default Vue.extend({
  props: {
    communityId: [String, Number]
  },
  data: () => ({
    loading: false
  }),
  computed: {
    communities(): any {
      return this.$store.state.community?.communities;
    }
  },
  async mounted() {
    // const masterplan = await this.$store.dispatch('community/fetchMasterplan', this.communityId);
    // this.sitingMap.setMasterplan(masterplan);
  }
});
