import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import sources from './sources';
import layers from './layers';

mapboxgl.accessToken = 'pk.eyJ1IjoiYXJjaGlzdGFyciIsImEiOiJjamQ1ZzI3a24yaWdoMndwYXg0b3dpemlwIn0.I43D7FMuuWvu-mNAEc-rXQ';

export default class CommunityMap {
  container = undefined as undefined | string;
  mapbox = undefined as undefined | mapboxgl.Map;

  async init(container) {
    this.mapbox = new mapboxgl.Map({
      container,
      style: 'mapbox://styles/archistarr/ckcpqmkqf0bk41imi6n5swmdz',
      minZoom: 0,
      maxZoom: 24,

      zoom: 3.617834050683471,
      center: {
        lng: 132.2538974214807,
        lat: -25.35336367127617
      },
      bearing: 0,
      pitch: 0,

      antialias: true,
      transformRequest: (url: string) => ({
        url,
        // Inject headers if request is for archistar
        ...(url.includes('https://api.archistar.ai/') && {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('tka') }
        })
      })
    });

    // Injecting map object into window.map for faster debugging
    window.map = this.mapbox;

    await this.once('load');

    // Load default sources
    sources.forEach(source => {
      this.mapbox?.addSource(source.name, source.props as any);
    });

    // Load default layers
    layers.forEach((layer: any) => {
      this.mapbox?.addLayer(layer);
      if (layer.style) {
        this.mapbox?.setPaintProperty(layer.id, layer.style.property, layer.style.value);
      }
    });

    return true;
  }

  async once(event: string) {
    return new Promise(resolve => {
      this.mapbox?.once(event, data => {
        resolve(data);
      });
    });
  }

  async setMasterplan(masterplan: Masterplan) {
    const source: GeoJSONSource = this.mapbox?.getSource('masterplan') as any;

    // await this.once('load');

    source.setData({
      type: 'FeatureCollection',
      features: masterplan.features as any
    });

    this.mapbox?.jumpTo({
      zoom: 19.254239562793586,
      pitch: 78.50000000000003,
      bearing: -174.9422896334764,
      center: masterplan.info.location.coordinates as any
    });
  }
}
