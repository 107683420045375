import { RouteConfig } from 'vue-router/types/router';
import Setup from '../views/Setup/Layout.vue';

export default [
  {
    path: '/setup/',
    name: 'Setup',
    component: Setup,
    redirect: '/setup/location',
    children: [
      {
        name: 'Location',
        path: 'location',
        component: () => import('@/views/Setup/Location.vue'),
        props: route => Object.assign({}, route.query, route.params)
      },
      {
        name: 'Marketing',
        path: 'marketing',
        component: () => import('@/views/Setup/Marketing.vue'),
        props: route => Object.assign({}, route.query, route.params)
      },
      {
        name: 'Analytics',
        path: 'analytics',
        component: () => import('@/views/Setup/Analytics.vue'),
        props: route => Object.assign({}, route.query, route.params)
      }
    ],
    props: route => Object.assign({}, route.query, route.params)
  }
] as Array<RouteConfig>;
