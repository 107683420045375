export default [
  {
    id: 'locality',
    type: 'fill',
    source: 'adminbounds',
    'source-layer': 'locality',
    minzoom: 7,
    layout: {
      visibility: 'visible'
    },
    style: {
      property: 'fill-opacity',
      value: 0
    },
    paint: {
      'fill-color': '#33CC99'
    }
  },
  {
    id: 'lga',
    type: 'fill',
    source: 'adminbounds',
    'source-layer': 'lga',
    minzoom: 6,
    maxzoom: 24,
    layout: {
      visibility: 'visible'
    },
    style: {
      property: 'fill-opacity',
      value: 0
    },
    paint: {
      'fill-opacity': 0,
      'fill-color': '#FFFFFF',
      'fill-outline-color': '#FFFFFF'
    }
  },
  {
    id: 'Zoning',
    style: {
      property: 'fill-opacity',
      value: 0.5
    },
    type: 'fill',
    source: 'zoning',
    'source-layer': 'zoning',
    minzoom: 10,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': ['case', ['has', 'as_color'], ['get', 'as_color'], '#000000']
    },
    filter: true
  },
  {
    id: 'LotSizeFilter',
    style: {
      property: 'fill-opacity',
      value: 1
    },
    type: 'fill',
    source: 'lotarea',
    'source-layer': 'lotarea',
    minzoom: 14,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'class'],
        0,
        'hsl(160, 52%, 85%)',
        27,
        'hsl(160, 60%, 50%)',
        63,
        'hsl(160, 62%, 32%)',
        86,
        'hsl(177, 62%, 32%)'
      ]
    },
    filter: true
  },
  {
    id: 'waggawaggaflood',
    type: 'raster',
    source: 'waggawaggaflood',
    minzoom: 10,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'raster-opacity': 0.5
    }
  },
  {
    id: 'contamination',
    style: {
      property: 'fill-opacity',
      value: 0.5
    },
    type: 'fill',
    source: 'contamination',
    'source-layer': 'Contamination',
    minzoom: 10,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': ['match', ['get', 'value'], 'Low', ' #FAD8BD', 'Medium', '#EDA87E', 'High', '#E87F4F', '#888888']
    },
    filter: ['all', ['in', ['get', 'value'], ['literal', ['Medium', 'High']]], true]
  },
  {
    id: 'overlay_flood',
    style: {
      property: 'fill-opacity',
      value: 0.5
    },
    type: 'fill',
    source: 'overlays',
    'source-layer': 'Flood',
    minzoom: 10,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': ['case', ['has', 'as_color'], ['get', 'as_color'], '#5499C7']
    },
    filter: [
      'all',
      [
        '!',
        [
          'in',
          ['get', 'as_region_guid'],
          ['literal', ['5e83d7ca-b63f-40b4-0ecc-ec5f794efe61', '8c71425b-a567-48a0-b44c-22923ddbc5b0']]
        ]
      ],
      true
    ]
  },
  {
    id: 'datasets_flood',
    style: {
      property: 'fill-opacity',
      value: 0.5
    },
    type: 'fill',
    source: 'datasets_flood',
    'source-layer': 'default',
    minzoom: 10,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': ['case', ['has', 'as_color'], ['get', 'as_color'], '#5499C7']
    },
    filter: true
  },
  {
    id: 'overlay_bushfire',
    style: {
      property: 'fill-opacity',
      value: 0.5
    },
    type: 'fill',
    source: 'overlays',
    'source-layer': 'Bushfire',
    minzoom: 10,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': [
        'case',
        ['==', ['get', 'value'], 'Low Potential Bushfire Intensity'],
        '#f59e42',
        ['==', ['get', 'value'], 'Medium Potential Bushfire Intensity'],
        '#f57e42',
        ['==', ['get', 'value'], 'High Potential Bushfire Intensity'],
        '#f55d42',
        ['==', ['get', 'value'], 'Very High Potential Bushfire Intensity'],
        '#f54b42',
        ['has', 'as_color'],
        ['get', 'as_color'],
        '#E67E22'
      ]
    },
    filter: true
  },
  {
    id: 'overlay_heritage',
    style: {
      property: 'fill-opacity',
      value: 0.5
    },
    type: 'fill',
    source: 'overlays',
    'source-layer': 'Heritage',
    minzoom: 10,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': ['case', ['has', 'as_color'], ['get', 'as_color'], '#F5CBA6']
    },
    filter: true
  },
  {
    id: 'infrastructure_electricity',
    type: 'line',
    source: 'infrastructure',
    'source-layer': 'electricity',
    minzoom: 10,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'line-opacity': 0.75,
      'line-color': '#FF0000',
      'line-width': ['step', ['get', 'capacity_kv'], 1, 25, 2, 100, 3, 200, 4, 500, 6]
    },
    filter: ['all', ['==', ['get', 'class'], 'Overhead'], true]
  },
  {
    id: 'infrastructure_electricity_underground',
    type: 'line',
    source: 'infrastructure',
    'source-layer': 'electricity',
    minzoom: 10,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'line-opacity': 0.75,
      'line-color': '#0000FF',
      'line-dasharray': [6, 6],
      'line-width': ['step', ['get', 'capacity_kv'], 1, 25, 2, 100, 3, 200, 4, 500, 6]
    },
    filter: ['all', ['==', ['get', 'class'], 'Underground'], true]
  },
  {
    id: 'FilteredLots',
    style: {
      property: 'fill-opacity',
      value: 0.5
    },
    type: 'fill',
    source: 'properties',
    'source-layer': 'properties',
    minzoom: 14,
    maxzoom: 24,
    layout: {
      visibility: 'visible'
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': '#ef9ea4',
      'fill-outline-color': '#FFFFFF'
    },
    filter: ['all', false, true]
  },
  {
    id: 'Contours2d',
    style: {
      property: 'fill-opacity',
      value: 0.1
    },
    type: 'fill',
    source: 'mapbox-terrain',
    'source-layer': 'contour',
    minzoom: 13,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'ele'],
        0,
        '#244b5c',
        100,
        'hsl(160, 60%, 50%)',
        250,
        '#15513d'
      ],
      'fill-outline-color': '#000000'
    },
    filter: ['all', ['all', ['>', ['get', 'ele'], 1]]]
  },
  {
    id: 'ContoursLabels2d',
    style: {
      property: 'text-opacity',
      value: 1
    },
    type: 'symbol',
    source: 'mapbox-terrain',
    'source-layer': 'contour',
    minzoom: 13,
    maxzoom: 24,
    layout: {
      visibility: 'none',
      'text-size': 16,
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-field': '{ele}',
      'text-offset': [0, 0],
      'text-anchor': 'center',
      'symbol-placement': 'line',
      'symbol-spacing': 250,
      'symbol-avoid-edges': false
    },
    paint: {
      'text-opacity': 0,
      'text-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'text-color': '#171717'
    },
    filter: ['all', ['all', ['>', ['get', 'ele'], 1]]]
  },
  {
    id: 'ContoursLine2d',
    style: {
      property: 'line-opacity',
      value: 1
    },
    type: 'line',
    source: 'mapbox-terrain',
    'source-layer': 'contour',
    minzoom: 13,
    maxzoom: 24,
    layout: {
      visibility: 'none',
      'line-cap': 'butt',
      'line-join': 'miter',
      'line-miter-limit': 2
    },
    paint: {
      'line-opacity': 0,
      'line-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'line-color': '#5e5e5e'
    },
    filter: ['all', ['all', ['>', ['get', 'ele'], 1]]]
  },
  {
    id: 'DetailedContoursLabels2d',
    style: {
      property: 'text-opacity',
      value: 1
    },
    type: 'symbol',
    source: 'contours',
    'source-layer': 'contours',
    minzoom: 13,
    maxzoom: 24,
    layout: {
      visibility: 'none',
      'text-size': 16,
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-field': '{elevation}',
      'text-offset': [0, 0],
      'text-anchor': 'center',
      'symbol-placement': 'line',
      'symbol-spacing': 250,
      'symbol-avoid-edges': false
    },
    paint: {
      'text-opacity': 0,
      'text-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'text-color': '#171717'
    },
    filter: ['all', ['all', ['>', ['get', 'elevation'], 1]], true]
  },
  {
    id: 'DetailedContoursLine2d',
    style: {
      property: 'line-opacity',
      value: 1
    },
    type: 'line',
    source: 'contours',
    'source-layer': 'contours',
    minzoom: 13,
    maxzoom: 24,
    layout: {
      visibility: 'none',
      'line-cap': 'butt',
      'line-join': 'miter',
      'line-miter-limit': 2
    },
    paint: {
      'line-opacity': 0,
      'line-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'line-color': '#5e5e5e'
    },
    filter: ['all', ['all', ['>', ['get', 'elevation'], 1]], true]
  },
  {
    id: 'Lots',
    style: {
      property: 'fill-opacity',
      value: 0
    },
    type: 'fill',
    source: 'properties',
    'source-layer': 'properties',
    minzoom: 14,
    maxzoom: 24,
    layout: {
      visibility: 'visible'
    },
    paint: {
      'fill-opacity': 1,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': '#ef9ea4'
    }
  },
  {
    id: 'Lots_Outlines',
    style: {
      property: 'line-opacity',
      value: 0.8
    },
    type: 'line',
    source: 'properties',
    'source-layer': 'properties',
    minzoom: 14,
    maxzoom: 24,
    layout: {
      visibility: 'visible',
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-opacity': 0,
      'line-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'line-color': '#CCCCCC'
    }
  },
  {
    id: 'HighlightLots',
    style: {
      property: 'fill-opacity',
      value: 0.75
    },
    type: 'fill',
    source: 'properties',
    'source-layer': 'properties',
    minzoom: 14,
    maxzoom: 24,
    filter: ['!', true],
    layout: {
      visibility: 'visible'
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-color': '#51abad'
    }
  },
  {
    id: 'HighlightLotsOutline',
    style: {
      property: 'line-opacity',
      value: 0.75
    },
    type: 'line',
    source: 'properties',
    'source-layer': 'properties',
    minzoom: 14,
    maxzoom: 24,
    filter: ['!', true],
    layout: {
      visibility: 'visible',
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#414141',
      'line-width': 3
    }
  },
  {
    id: 'Zoning_Labels',
    style: {
      property: 'text-opacity',
      value: 0.5
    },
    type: 'symbol',
    source: 'zoning',
    'source-layer': 'zoning',
    minzoom: 15,
    maxzoom: 24,
    layout: {
      visibility: 'none',
      'text-size': 12,
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-field': '{as_label}',
      'text-offset': [0, 0],
      'text-anchor': 'center'
    },
    paint: {
      'text-opacity': 0,
      'text-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'text-color': '#000000'
    },
    filter: true
  },
  {
    id: 'Street_Numbers',
    style: {
      property: 'text-opacity',
      value: 1
    },
    type: 'symbol',
    source: 'gnaf_addresses',
    'source-layer': 'gnaf_street_numbers',
    minzoom: 15,
    maxzoom: 24,
    layout: {
      visibility: 'visible',
      'text-field': ['to-string', ['get', 'street_number']],
      'text-size': ['interpolate', ['exponential', 2], ['zoom'], 5, 6, 17, 10, 19, 12, 21, 14],
      'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
      'text-offset': [0, 0],
      'text-anchor': 'center',
      'symbol-placement': 'point'
    },
    paint: {
      'text-color': '#888888'
    },
    filter: true
  },
  {
    id: 'MapboxHouseNum',
    style: {
      property: 'text-opacity',
      value: 1
    },
    type: 'symbol',
    source: 'composite',
    'source-layer': 'housenum_label',
    minzoom: 15,
    maxzoom: 24,
    layout: {
      visibility: 'visible',
      'text-field': ['to-string', ['get', 'house_num']],
      'text-size': ['interpolate', ['exponential', 2], ['zoom'], 5, 6, 17, 10, 19, 12, 21, 14],
      'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
      'text-offset': [0, 0],
      'text-anchor': 'center',
      'symbol-placement': 'point'
    },
    paint: {
      'text-color': '#888888'
    },
    filter: false
  },
  {
    id: 'Building_Outlines',
    style: {
      property: 'line-opacity',
      value: 0.8
    },
    type: 'line',
    source: 'buildings',
    'source-layer': 'buildings',
    minzoom: 15,
    maxzoom: 24,
    layout: {
      visibility: 'none',
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-opacity': 0,
      'line-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'line-color': '#c7c7c7',
      'line-width': 1
    },
    filter: true
  },
  {
    id: 'Buildings',
    style: {
      property: 'fill-extrusion-opacity',
      value: 0.8
    },
    type: 'fill-extrusion',
    source: 'buildings',
    'source-layer': 'buildings',
    minzoom: 15,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-extrusion-opacity': 0.6,
      'fill-extrusion-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-extrusion-height': ['to-number', ['get', 'max_actual_building_roof_height_m']],
      'fill-extrusion-height-transition': {
        duration: 500,
        delay: 0
      },
      'fill-extrusion-color': '#c7c7c7'
    },
    filter: true
  },
  {
    id: 'Contours3d',
    type: 'fill-extrusion',
    source: 'mapbox-terrain',
    'source-layer': 'contour',
    minzoom: 13,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-extrusion-opacity': 0.7,
      'fill-extrusion-height': ['to-number', ['get', 'ele']],
      'fill-extrusion-color': [
        'interpolate',
        ['linear'],
        ['get', 'ele'],
        0,
        '#244b5c',
        100,
        'hsl(160, 60%, 50%)',
        250,
        '#15513d'
      ]
    },
    filter: ['all', ['all', ['>', ['get', 'ele'], 1]]]
  },
  {
    id: 'ContoursLabels3d',
    style: {
      property: 'text-opacity',
      value: 1
    },
    type: 'symbol',
    source: 'mapbox-terrain',
    'source-layer': 'contour',
    minzoom: 13,
    maxzoom: 24,
    layout: {
      visibility: 'none',
      'text-size': 16,
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-field': '{ele}',
      'text-offset': [0, 0],
      'text-anchor': 'center',
      'symbol-placement': 'point',
      'symbol-spacing': 250,
      'symbol-avoid-edges': false
    },
    paint: {
      'text-opacity': 0,
      'text-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'text-color': '#171717'
    },
    filter: ['all', ['all', ['>', ['get', 'ele'], 1]]]
  },
  {
    id: 'MaxBuildingHeight',
    style: {
      property: 'fill-extrusion-opacity',
      value: 0.6
    },
    type: 'fill-extrusion',
    source: 'plan_maxbldheightm',
    'source-layer': 'maxbldheight',
    minzoom: 14,
    maxzoom: 24,
    layout: {
      visibility: 'none'
    },
    paint: {
      'fill-extrusion-opacity': 0,
      'fill-extrusion-opacity-transition': {
        duration: 500,
        delay: 100
      },
      'fill-extrusion-height': ['to-number', ['get', 'value']],
      'fill-extrusion-height-transition': {
        duration: 500,
        delay: 0
      },
      'fill-extrusion-color': [
        'interpolate',
        ['linear'],
        ['to-number', ['get', 'value']],
        0,
        'hsl(216, 8%, 76%)',
        15,
        'hsl(213, 23%, 49%)',
        50,
        'hsl(213, 23%, 23%)'
      ]
    },
    filter: true
  },
  {
    id: 'locality-outline',
    type: 'line',
    source: 'adminbounds',
    'source-layer': 'locality',
    minzoom: 7,
    layout: {
      visibility: 'none'
    },
    paint: {
      'line-opacity': 1,
      'line-color': '#666',
      'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 13, 1, 18, 3]
    }
  },
  {
    id: 'lga-outline',
    type: 'line',
    source: 'adminbounds',
    'source-layer': 'lga',
    minzoom: 7,
    layout: {
      visibility: 'none'
    },
    paint: {
      'line-opacity': 1,
      'line-color': '#cc6699',
      'line-width': ['interpolate', ['exponential', 1], ['zoom'], 13, 1, 18, 3]
    }
  },
  {
    id: 'Nearmap',
    type: 'raster',
    source: {
      type: 'raster',
      tiles: [
        'https://au0.nearmap.com/maps?version=2&x={x}&y={y}&z={z}&nml=Vert&sessionid=4dbad7ea-93ec-48c4-a726-2917ff341342&apikey=OTNkOTczNTgtNGY4NS00NjZjLTk1MzItNThmYTk2MDdlOGEw'
      ],
      tileSize: 256,
      minzoom: 12,
      maxzoom: 21,
      bounds: [113, -43, 154, -11]
    },
    layout: {
      visibility: 'none'
    }
  }
];
