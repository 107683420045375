import Vue from 'vue';
import Vuex from 'vuex';
import modal from './modules/modal';
import community from './modules/community';

Vue.use(Vuex);
export interface RootState {
  loading: boolean;
}

const storePattern = {
  state: {
    loading: false
  } as RootState,
  mutations: {
    SET_LOADING(state, value: boolean) {
      state.loading = value;
    }
  },
  actions: {},
  modules: {
    modal,
    community
  }
};

// type Getters = {
//   [Property in keyof typeof storePattern.modules as Property]: {
//     state: any;
//     getters: any;
//     mutations: any;
//     actions: any;
//   }
// };

// const test: Getters = {};

export default new Vuex.Store(storePattern);
