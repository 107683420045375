



import Vue from 'vue';
export default Vue.extend({
  props: {
    communityId: [String, Number]
  },
  async mounted() {
    await this.sitingMap.init('map');
  }
});
